@import "./flags.scss";

.react-tel-input {
  position: relative;
  width: 100%;
  :disabled {
    cursor: not-allowed;
  }


.form-control {
  padding: 0.43rem 4rem;
  transition: box-shadow ease .25s, border-color ease .25s;
  color: #495057;
  &:focus {
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
    &.invalid-number {
      box-shadow: 0 0 0 0.2rem rgba(222,0,0,.25);
    }
  }
  &.invalid-number {
    border: 1px solid #f44336;
  }
}
.flag-dropdown {
  position: absolute;
  top: 1px;
  bottom: 0;
  padding: 0;
  height: 45px;
  border-radius: 3px 0 0 3px;
  background-color: #f5f5f5;
  left: 1px;
  border-right: 1px solid #cacaca;
  &:hover, &:focus {
    cursor: pointer;
  }
  &.open {
    z-index: 2;
  }
}
input[disabled] {
  &+.flag-dropdown {
    &:hover {
      cursor: default;
      .selected-flag {
        background-color: transparent;
      }
    }
  }
}
.selected-flag {
  outline: none;
  position: relative;
  width: 52px;
  height: 100%;
  padding: 0 0 0 11px;
  border-radius: 3px 0 0 3px;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 0;
    width: 100%;
    border-radius: 4px 2px 2px 4px;
    border: 1px solid transparent;
    transition: box-shadow ease .25s, border-color ease .25s;
  }
  &:focus, &.open {
    &:before {
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
    }
  }
  .flag {
    position: absolute;
    top: 50%;
    margin-top: -6px;
  }
  .arrow {
    position: relative;
    top: 50%;
    margin-top: -1px;
    left: 29px;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid #555;
    &.up {
      border-top: none;
      border-bottom: 4px solid #555;
    }
  }
}
.country-list {
  outline: none;
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 18px rgba(0, 0, 0, 0.25);
  background-color: white;
  width: 300px;
  max-height: 220px;
  overflow-y: scroll;
  border-radius: 7px;
  .flag {
    display: inline-block;
    position: absolute;
    left: 13px;
    top: 8px;
  }
  .divider {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #ccc;
  }
  .country {
    position: relative;
    padding: 1px 9px 13px 46px;
    .dial-code {
      color: #6b6b6b;
    }
    &:hover {
      background-color: #f1f1f1;
    }
    &.highlight {
      background-color: #f1f1f1;
    }
  }
  .flag {
    margin-right: 7px;
    margin-top: 2px;
  }
  .country-name {
    margin-right: 6px;
  }
  .search {
    z-index: 2;
    position: sticky;
    top: 0;
    background-color: #fff;
    padding: 10px 0 6px 10px;
  }
  .search-emoji {
    display: none;
    font-size: 15px;
  }
  .search-box {
    border: 1px solid #cacaca;
    border-radius: 3px;
    font-size: 15px;
    line-height: 15px;
    margin-left: 6px;
    padding: 3px 8px 5px;
    outline: none;
  }
  .no-entries-message {
    padding: 7px 10px 11px;
    opacity: .7;
  }
}
.invalid-number-message {
  position: absolute;
  z-index: 1;
  font-size: 13px;
  left: 25px;
  top: -7px;
  background: #fff;
  padding: 0 5px;
  color: #de0000;
}
.special-label {
  display: none;
  position: absolute;
  z-index: 1;
  font-size: 13px;
  left: 25px;
  top: -7px;
  background: #fff;
  padding: 0 5px;
  white-space: nowrap;
}

}
