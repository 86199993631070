/* @media only screen and  (min-width: 1200px) and (max-width: 1399px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1140px;
    }
}

@media only screen and (min-width: 992px) and (max-width:1199px) {
    .container, .container-lg, .container-md, .container-sm {
        max-width: 960px;
    }
}

@media only screen and  (min-width: 768px) and (max-width: 991px) {
    .container, .container-md, .container-sm {
        max-width: 720px;
    }
}

@media only screen and  (min-width: 576px) and (max-width: 767px) {
    .container, .container-sm {
        max-width: 540px;
    }
} */

:root {
    --sgds-warning-rgb: 218,126,7;
}


.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    padding-left: 10px;
    padding-right: 10px;
}

.header .sgds-masthead-band {
    background-color: var(--sgds-masthead-background-color);
}

body,
h1,
h2,
h3,
h4 {
    font-family: 'Lato', sans-serif;
}

.modal-title i {
    display: none;
}

.my-8 {
    margin-top: 0rem !important;
}

.m-30 {
    margin: 30px;
}

.m-15 {
    margin: 15px;
}

.mTop-10 {
    margin-top: 10px;
}

.mTop-50 {
    margin-top: 50px;
}

.mTop-30 {
    margin-top: 15px;
}

.marginTop-30 {
    margin-top: 30px;
}

.marginBot-30 {
    margin-bottom: 30px;
}

.mTop-80 {
    margin-top: 80px;
}

.mBot-20 {
    margin-bottom: 10px;
}

.mBot-30 {
    margin-bottom: 15px;
}

.mTop-120 {
    margin-top: 120px;
}

.mTop-negative {
    margin-top: -125px;
}

.pLeft-15 {
    padding-left: 15px;
}

.pRight-15 {
    padding-left: 15px;
}


.row {
    padding-left: 15px;
    padding-right: 15px;
}

.breadcrumb {
    margin-left: 1.2rem;
    margin-bottom: 2rem;
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;
}

.breadcrumb a {
    text-decoration: none;
}

.modal-dialog {
    min-width: 1024px;
}

footer .footer-bottom hr {
    margin: 0 0 2rem 0;
    opacity: 1;
}

.sgds.footer .footer-top {
    background-color: #111f36;
}

.sgds.footer .footer-bottom {
    background-color: #111f36;
    border-top: 1px solid #111f36;
}

@media (min-width: 992px) {
    footer .footer-bottom .footer-copyrights.row {
        text-align: right;
    }
}

.btn-caas,
.upload-btn-container .btn {
    Padding: 8px 32px 8px 32px;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    font-family: 'Lato';
    font-style: normal;
    border-radius: 88px;
}

.btn-primary-caas {
    background-color: #C70068;
    border-color: #970c55;
    min-width: 172px;
}

.btn-primary:active {
    background-color: #970c55;
    border-color: #970c55;
    color: #fff;
}

.btn-primary:focus,
.btn-primary:hover {
    background-color: #970c55;
    border-color: #970c55;
    color: #fff;
}

.btn-primary.disabled, .btn-primary:disabled {
    background-color: #fff;
    border-color: #5925dc;
    color: #633AB3;
}

.btn-default-caas {
    background-color: #fff;
    color: #633AB3;
    min-width: 172px;
}

.btn-default-caas:focus,
.btn-default-caas:hover {
    background-color: #970c55;
    color: #fff;
    border-color: #970c55;
}

.btn-gap {
    margin-right: 8px;
}

.card.sgds,
.selected-card .card {
    background: #FFFFFF;
    border: 1px solid #D6D6D6;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.card.sgds:hover,
.selected-card .card:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.cardtile-height {
    height: 64px;
}

.cardbody-height {
    height: 317px;
}

.cardtext-height {
    height: 124px;
}

.red-color {
    color: red;
}

/* stepper */
.wrapper-progressBar {
    width: 100%
}

.progressBar li {
    list-style-type: none;
    float: left;
    width: 33%;
    position: relative;
    text-align: center;
}

.progressBar li:before {
    content: " ";
    line-height: 30px;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    border: 1px solid #ddd;
    border-left: none;
    display: block;
    text-align: center;
    margin: 8.5px auto 0px;
    background-color: #eee;
}

.progressBar li:after {
    content: "";
    position: absolute;
    width: 97%;
    height: 5px;
    background-color: #eee;
    border: 1px solid #ddd;
    border-right: none;
    top: 15px;
    left: -50%;
    z-index: -1;
}

.progressBar li:first-child:after {
    content: none;
}

.progressBar li.active {
    color: dodgerblue;
}

.progressBar li.active:before {
    border-color: dodgerblue;
    background-color: dodgerblue
}

.progressBar .active:after {
    background-color: dodgerblue;
}

/* stepper end*/

.pad-0 {
    padding-left: 0;
    padding-right: 0;
}

.form-label {
    margin-bottom: 5px;
    font-weight: 400;
    width: calc(100% - 30px);
}

#RFS-StepButton.active {
    background-color: #fff;
    border: 4px solid #008649;
}

#RFS-StepButton.active span {
    font-weight: 400;
    color: #008649;
}

#RFS-StepButton.completed {
    background-color: #008649;
}

.btn-center {
    text-align: center;
}

/* background: #FFFFFF;
border: 4px solid #D6D6D6; */

@media (min-device-width: 1281px) {
    form br {
        content: ' ';
    }

    form br:after {
        content: ' ';
    }
}

.react-bs-container-body {
    margin-top: -16px;
}

.react-bs-container-body td {
    width: auto;
}

.react-bs-table-tool-bar {
    margin-bottom: 10px;
    margin-left: -15px;
}

.upload-btn-container .btn {
    min-width: 160px;
}

.mTop-70 {
    margin-top: 60px;
}

.mLeft-15 {
    margin-left: 15px;
}

.mRight-15 {
    margin-right: 15px;
}

.mLeft-26 {
    margin-left: 26px;
}

.mLeft-30 {
    margin-left: 30px;
}

.mRight-30 {
    margin-right: 30px;
}

.mLeft-15 {
    margin-left: 15px;
}

.mLeft-5 {
    margin-left: 5px;
}

.mRight-5 {
    margin-right: 5px;
}

.mLeft-10 {
    margin-left: 10px;
}

.mRight-10 {
    margin-right: 10px;
}

.mMinusLeft-15 {
    margin-left: -15px;
}

.mMinusRight-20 {
    margin-right: -20px;
}

.mMinusLeft-20 {
    margin-left: -20px;
}

.mMinusRight-15 {
    margin-right: -15px;
}

@media screen and (max-width:1413px) and (min-width: 330px) {
    .modal-dialog {
        min-width: auto;
    }

    .cardbody-height {
        height: 400px;
    }

    .card-title {
        font-size: 1rem;
    }

    .cardtile-height {
        height: 80px;
    }

    .card .btn {
        min-width: 100%;
        margin-bottom: 15px;
    }

    .cardtext-height {
        height: 150px;
        font-size: 90%;
    }

    .col {
        flex: none;
    }

}

/* ipad Air media Queries */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1.0) {
    .card-width {
        width: 49%;
    }

    .mapPermitChecker .control-window {
        transform: translate3d(100px, 100px, 0px) !important;
    }
}

/* galaxy fold media query */
@media (min-width: 280px) and (max-width: 320px) {
    .cardtext-height {
        height: 185px;
        font-size: .9rem;
    }

    .cardbody-height {
        height: 420px;
    }

    .btn-gap {
        /* margin-right: 8px; */
        margin-bottom: 10px;
    }

    .mapPermitChecker .control-window {
        transform: translate3d(100px, 100px, 0px) !important;
    }
}

.accordion-item {
    max-height: 300px;
    overflow-y: auto;
}

.italic-font {
    font-style: italic;
}

.text-right {
    float: right;
}

.sr-only {
    display: none;
}

.leaflet-tooltip {
    width: 150px;
    white-space: normal;
}

.leaflet-control-search .search-input {
    height: 48px;
    font-size: large;
}

.leaflet-control-search .search-tip {
    font-size: large;
}

.leaflet-control-search .search-tooltip {
    max-height: 200px
}

.map-modal .modal-header {
    padding: 0.5rem
}

.map-modal .modal-content {

    margin: 0vh 0vh;

}

.map-modal .modal-header .btn-close {
    padding: 1.5rem
}

.map-modal .modal-title {
    line-height: 0;
    font-size: large;
}

.map-modal .modal-body {
    padding: 0.25rem
}

.map-modal .modal-footer {
    padding: 0rem;
    padding-top: 9px;
    justify-content: center;
}

.leaflet-container {
    max-width: 100%;
    max-height: 100%;
}

.map-modal .modal-fullscreen .modal-body {
    overflow-y: unset;
    margin: -5px;
}

@media screen and (min-width:280px) and (max-width:550px) {
    .map-modal .modal-dialog {
        min-width: 100%;
    }

    .leaflet-control-window {
        min-width: 100%;
        margin: 14px;
    }

    .mapPermitChecker .control-window {
        transform: translate3d(100px, 100px, 0px) !important;
    }
}

.form-is-invalid {
    border: 1px solid #d7260f;
}

.form-is-invalid:focus {
    border-color: #d7260f;
    box-shadow: 0 0 0 0.125rem rgb(215 38 15 / 25%);
}

@media screen and (min-width:1713px) {
    .card-width {
        width: 25%;
    }

    .btn-gap {
        margin-right: 15px;
    }

    .btn-primary-caas,
    .btn-default-caas {
        min-width: 160px;
    }

    .mapPermitChecker .control-window {
        transform: translate3d(300px, 150px, 0px) !important;
    }
}

.form-check-input.is-invalid:checked {
    background-color: #0f71bb;
    border-color: #0f71bb;
}

.leaflet-control-window .close {
    display: none;
}

.control-window {
    transform: translate3d(139px, 300px, 0px);
}

.map-modal-alert .modal-header {
    padding: 0.5rem
}

.required-field::after {
    content: "*";
    color: red;
    font-weight: bold;
}

.userImagePreviewDiv {
    border: 1px solid #ececec;
    padding: .8%;
    border-radius: 5px;

}

.userImagePreview {
    width: 100%;
}

.email-verification-button {
    margin-top: 7px;
    background-color: #000;
}

@media screen and (min-width:427px) {
    .mLeft-30-fileUpload {
        margin-left: 30px;
    }
}

.otpPrefix-box {
    min-width: 90px;
}

.otpPrefix-After::after {
    content: "-";
    font-weight: bold;
    font-size: x-large;
}


@media screen and (min-width:768px) {
    .otpPrefix-box {
        min-width: 90px;
        margin-left: -15px;
        padding-right: 25px;
        ;
    }

    .emailOTP-box {
        padding-right: 15px;

    }

    .otpPrefix-After::after {
        content: "-";
        font-weight: bold;
        font-size: x-large;
        margin-left: -12px;
    }

}

.otpPrefix-After {
    width: 1%;
    margin-left: 5px;
}

.overlay-spinner .modal-content {
    background: transparent;
    border: none;
    text-align: center;
}

.card-img-top {
    height: auto;
}

@media only screen and (min-width: 768px) and (max-width: 1100px) {
    .card-width {
        width: 350px
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .card-width {
        width: 100%;
    }
}

.bold-label {
    font-weight: 700;
    margin: 3px;
}

.selected-card .card-title-padding {
    margin: 4px 24px 24px 0;
}

.selected-card .card .card-body {
    padding: 1.2rem 1.4rem;
}

.selected-card {
    margin-bottom: 16px;
}

.selected-card .card .sgds.nav-tabs {
    gap: .3rem;
    flex-wrap: nowrap;
}

.selected-card .card .card {
    box-shadow: none;
    cursor: pointer;
    transition: all 0.4s ease-in;
}

.selected-card .card .card.is-active:after {
    content: "";
    width: 0;
    height: 0;
    border-top: 25px solid #fff;
    border-left: 20px solid transparent;
    position: absolute;
    top: 105px;
    left: 65px;
}


.selected-card .lead {
    font-size: .9rem;
}

.box {
    width: calc(50% - 8px);
    height: 120px;
    background-color: #fff;
    padding: 5px 5px 5px 5px;
    position: relative;
    float: left;
    box-shadow: none;
    cursor: pointer;
    transition: all 0.4s ease-in;
}

.selected-card .box:hover,
.selected-card .box.arrow-bottom.is-active {
    box-shadow: inset -5px -5px 9px rgba(255, 255, 255, 0.45), inset 5px 5px 9px rgba(94, 104, 121, 0.3);
}

.selected-card .box.arrow-bottom.is-active:after {
    content: " ";
    position: absolute;
    right: calc(50% - 20px);
    bottom: -15px;
    border-top: 15px solid #fff;
    border-right: 15px solid transparent;
    border-left: 20px solid transparent;
    border-bottom: none;
}

/**Search box */
.search-box {
    margin: 0px;
    padding: 8px 0;
    margin-top: 0px;
}


.bg-pblue {
    background-color: #219ED4;
}

.search-box .form-title {
    margin-top: -13px;
    margin-bottom: -13px;
    color: #fff;
    font-weight: 100;
}

.search-box p {
    color: #B0E7FF;
    margin: 0;
}

.search-box .input-group {
    -webkit-box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
}

.search-box .input-group-addon {
    border: none;
}

.search-box .form-title strong {
    display: block;
}

.search-box .form-control {
    height: 40px;
    border-radius: 0;
    border: none;
}

.search-box .form-control:focus {

    box-shadow: 0px 0px 0px 0px #ffffff !important;

}

.search-box .input-group .input-group-addon [type="submit"],
.search-box .input-group .input-group-addon button {
    border: none;
    border-radius: 0;
}

.search-box .btn {
    padding: 0 54px;
    line-height: 60px;
}

.search-box .btn-primary {
    padding: 0 28px;
    line-height: 40px;
    background: #d7260f;
    color: #fff;
}

.search-box .btn-primary:hover {
    color: #fff;
    background-color: #286090;
    border-color: #204d74;
}

.search-box .input-group+p {
    color: #fff;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    margin-top: 15px;
    margin-bottom: 0;
}

.search-box .input-group+p strong {
    margin-right: 40px;
}

.btn-action-width {
    width: 120px;
}

.pLeft-0 {
    padding-left: 0;
}

.pRight-0 {
    padding-right: 0;
}

.confirm-center-footer {
    display: block;
}

.selected-card .full-width-tab {
    width: calc(100% - 8px)
}

.align-left {
    text-align: left;
    padding-left: 24px !important;
}

@media only screen and (min-width: 1160px) and (max-width: 1421px) {
    .col-responsive {
        flex: 0 0 auto;
        width: 31.33333333%;
        margin: 3px;
        padding: 3px;
    }
}

@media only screen and (min-width: 870px) and (max-width: 1159px) {
    .col-responsive {
        flex: 0 0 auto;
        width: 49.9999%;
    }
}


.leaflet-touch .leaflet-control-zoom-display {
    width: 60px;
    height: 60px;
    font-size: 22px;
    line-height: 35px;
}

.leaflet-touch .leaflet-bar a,
.leaflet-touch .leaflet-toolbar-0>li>a {
    width: 46px;
    height: 60px;
    font-size: 30px;
    line-height: 55px;
    background-size: 316px 30px;
}

.leaflet-touch .leaflet-bar .leaflet-draw-draw-polygon {
    background: url('/public/images/polygon.png') no-repeat 9px center;
    background-size: 45px 50px;
    background-color: white;
}

.leaflet-touch .leaflet-bar .leaflet-draw-draw-marker {
    background: url('/public/images/marker.png') no-repeat 9px center;
    background-size: 45px 50px;
    background-color: white;
}

.leaflet-touch .leaflet-bar .leaflet-draw-edit-edit .leaflet-disabled {
    background: url('/public/images/edit.png') no-repeat 9px center;
    background-size: 45px 50px;
    background-color: white;
    opacity: .4
}

.leaflet-touch .leaflet-bar .leaflet-draw-edit-edit.leaflet-disabled {
    background: url('/public/images/edit.png') no-repeat 9px center;
    background-size: 45px 50px;
    background-color: rgb(160, 160, 160);
    opacity: .4;
}

.leaflet-touch .leaflet-bar .leaflet-draw-edit-edit {
    background: url('/public/images/edit.png') no-repeat 9px center;
    background-size: 45px 50px;
    background-color: white;
    background-position: 1px 1px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-remove.leaflet-disabled {
    background: url('/public/images/delete.png') no-repeat 9px center;
    background-size: 40px 40px;
    background-color: rgb(160, 160, 160);
    opacity: .4;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-remove {
    background: url('/public/images/delete.png') no-repeat 9px center;
    background-size: 40px 40px;
    background-color: white;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-remove.leaflet-disabled {
    opacity: .4;
}

.leaflet-touch .leaflet-draw-actions a,
.leaflet-touch .leaflet-control-toolbar .leaflet-toolbar-1>li>.leaflet-toolbar-icon {
    font-size: 25px;
    line-height: 55px;
    height: 55px;
}

.leaflet-touch .leaflet-draw-actions,
.leaflet-touch .leaflet-toolbar-1 {
    left: 55px;
}

.leaflet-touch .leaflet-draw-toolbar.leaflet-bar a:hover::after {
    font-size: 30px;
}

.leaflet-control-search .search-button {
    width: 45px !important;
    height: 45px!important;
    background-size: 35px!important;
}

.leaflet-control-search .search-button:hover {
    background: url('/public/images/search_icon.png') !important;
}

.leaflet-control-search .search-button {
    background: url('/public/images/search_icon.png') !important;
}
.caas-tbl td {
    vertical-align: top;
}

.box.arrow-bottom.text-dark .jumbotron {
    padding-top: 1rem;
}

.row .selected-card .card .card-body .clearfix .jumbotron p {
    line-height: 1.4em;
}

.breadcrumb {
    margin-bottom: 0.5rem;
}

.selected-card .card-title-padding {
    margin: -15px 1px 5px 0;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: "" !important;
}

.table>:not(caption)>*>* {
    padding: 0.25rem;
}

.introjs-button {
    background-color: #C70068;
    text-shadow: none;
    color: white
}

.add-row {
    float: right;
}

.team{
    margin-top: 133px !important;
    padding: 0px 0 !important;
}

.form-wrapper {
    background: #f6f9ff;
    padding: 30px !important;
    height: 100%;
    margin-top: 30px !important;
}

.map-tour-button {
    position: absolute;
    top: 280px;
    right: 11px;
    padding: 22px;
    z-index: 600;
    background: url('/public/images/info-icon.png') no-repeat center;
    background-size: 30px 34px;
    background-color: white;
}

.map-delete-button {
    position: absolute;
    top: 150px;
    right: 11px;
    padding: 22px;
    z-index: 600;
    background: url('/public/images/delete.png') no-repeat center;
    background-size: 30px 34px;
    background-color: white;
}

.visible {
    display: block;
}

.mBot-7 {
    margin-bottom: 7px;
}

.padding-0,
input[type=submit],
button[type=button] {
    cursor: pointer;
}

.color-green {
    color: green;
}

.marginLeft-45 {
    margin-left: 45px;
}

.mRight-55 {
    margin-right: 55px;
}

.text-align-left {
    text-align: left !important;
}

.mapPermitChecker {
    max-width: 60rem;
    min-height: 40rem;
    margin-top: 20px;
}

.permit-checker-modal .modal-content {
    min-width: min-content;

    margin-top: 30px;
}

.pdfLinkButton,
.pdfLinkButton :focus,
.pdfLinkButton:focus-visible,
.pdfLinkButton:focus {
    padding: 0;
    box-shadow: none;
    border: none;
    outline: 0;
}

.btn.removeAllLinkBtn,
.btn.removeAllLinkBtn :focus,
.btn.removeAllLinkBtn :focus-visible {
    box-shadow: none;
    border: none;
    outline: 0;
    background: none;
    padding:0;
    margin: 0;
    width: auto;
    min-width: auto;
}


.leaflet-touch .leaflet-control-container .leaflet-draw-toolbar .leaflet-draw-draw-polygon {
    background-position: 0px -3px;
}

.leaflet-touch .leaflet-control-container .leaflet-draw-toolbar .leaflet-draw-draw-marker {
    background-position: 0 0;
}

.leaflet-touch .leaflet-control-container .leaflet-draw-toolbar .leaflet-draw-edit-remove, .leaflet-touch .leaflet-control-container .leaflet-draw-toolbar .leaflet-draw-edit-remove.leaflet-disabled {
    background-position: 4px 5px;
}

.ref_num_txt{
    color: #1E6093;
}
.permit_num_txt{
    color: #c4006b;
    word-wrap :break-word;
}
.alert-box{
    padding:8px 1px;
}
.alert-text{
    padding-top:3px;
    padding-left:4px;
}
    
.fixed-bottom, .fixed-top{
    z-index: 100;
}

.leaflet-touch .leaflet-bar a {
    width: 50px !important;
    height: 50px !important;
    line-height: 50px !important;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-edit.leaflet-disabled {
    background-position: 0px -1px !important; 
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-edit {
    background-position: 0px -1px !important; 
}
.min-width-100px{
min-width: 100px !important;
}

.min-width-230px{
    min-width: 230px !important;
}

.max-width-100px{
    max-width: 100px !important;
}

.hide-map-controls{
    display: none;
}
.show-map-controls{
    display: block;
}
/*
.sk-dropdown{

}
.sk-dropdown-in{

}
.sk-dd-1, .sk-dd-2, .sk-dd-3, .sk-dd-4, .sk-dd-5, .sk-dd-6, .sk-dd-7, .sk-dd-8, .sk-dd-9, .sk-dd-10, .sk-dd-11{
} 

.sk-dd-1-in, .sk-dd-2-in, .sk-dd-3-in, .sk-dd-4-in, .sk-dd-5-in, .sk-dd-6-in, .sk-dd-7-in, .sk-dd-8-in, .sk-dd-9-in, .sk-dd-10-in, .sk-dd-11{
}
*/

.sg-button{
    background : url("/public/images/login-with-Singpass.svg");
    content: "";
    width: 228px;
    height: 48px;
    border-radius: 9px;
    border: 0px;
}
.sg-button :hover, 
.sg-button :active, 
.sg-button :focus, 
.sg-button :visited {
    background : url("/public/images/login-with-Singpass.svg");
    content: "";
    width: 228px;
    height: 48px;
    border-radius: 9px;
    border: 0px;
}